
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import ChatHistoryList from './ChatHistoryList.vue'

export default defineComponent({
  name: 'ChatHistoryPanel',
  components: {
    ChatHistoryList,
  },
  props: {
    groupedChatHistory: {
      type: Array,
      default: () => [],
    },
    vSessionId: {
      type: String,
      default: '',
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideClearAllDialog: false,
      clearing: false,
      deleteEntry: null,
    }
  },
  computed: {
    deleteDialogTitle() {
      return this.deleteEntry
        ? this.$t('aiChat.sureDeleteChat')
        : this.$t('aiChat.sureClearChat')
    },
    deleteDialogText() {
      return this.deleteEntry
        ? `${this.$t('global.delete')} ${this.deleteEntry.title}. ${this.$t(
            'aiChat.deleteChatText'
          )}`
        : this.$t('aiChat.clearChatText')
    },
  },
  methods: {
    ...mapActions('Chat', [
      'historyDeleteAll',
      'deleteChatHistory',
      'historyDelete',
      'deleteChatEntry',
      'historyRename',
      'updateChatTitle',
    ]),
    async submitDeleteHistory() {
      if (this.deleteEntry) {
        await this.deleteChatHistory()
      } else {
        await this.onClearAllChatHistory()
      }
    },
    async deleteChatHistory() {
      this.clearing = true
      const response = await this.historyDelete({
        conversation_id: this.deleteEntry.id,
        config: { vSessionId: this.vSessionId },
      })
      if (response.ok) {
        this.deleteChatEntry(this.deleteEntry.id)
        this.selectHistory({ messages: [] })
        this.onHideClearAllDialog()
      }
      this.clearing = false
    },
    loadMoreHistory() {
      this.$emit('loadMoreHistory')
    },
    async onClearAllChatHistory() {
      this.clearing = true
      const response = await this.historyDeleteAll({
        config: { vSessionId: this.vSessionId },
      })
      if (response.ok) {
        this.deleteChatHistory()
        this.selectHistory({ messages: [] })
        this.onHideClearAllDialog()
      } else {
        console.error('Error clearing chat history')
      }
      this.clearing = false
    },
    handleDeleteHistory(entry: any) {
      this.deleteEntry = entry
      this.hideClearAllDialog = true
    },
    async handleEditHistory(entry: any) {
      const response = await this.historyRename({
        conversation_id: entry.id,
        title: entry.title,
        config: { vSessionId: this.vSessionId },
      })
      if (response.ok) {
        this.updateChatTitle(entry)
      } else {
        console.error('Error renaming chat history')
      }
    },
    onHideClearAllDialog() {
      this.deleteEntry = null
      this.hideClearAllDialog = false
    },
    toggleClearAllDialog() {
      this.hideClearAllDialog = true
    },
    handleHistoryClick() {
      this.$emit('hideHistory')
    },
    selectHistory(entry: any) {
      this.$emit('selectHistory', entry)
    },
  },
  mounted() {},
})
