
import { defineComponent } from 'vue'
import Chat from './Chat.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Conversation, GroupedChatHistory } from '@/constants/aiChatModels'
export default defineComponent({
  name: 'AiAssistant',
  components: {
    Chat,
  },
  data() {
    return {
      isChatVisible: false,
      isChatHistoryOpen: false,
      showChatHistory: false,
      showSpinner: false,
    }
  },
  computed: {
    ...mapGetters('Environment', ['assistantSettings']),
    ...mapGetters('Security', ['userID']),
    ...mapState('Chat', ['isEnsure', 'chatHistory']),
    showAiAssistant() {
      return this.isEnsure
    },
    userPrefix() {
      return this.assistantSettings?.userPrefix
    },
    vSessionId() {
      return `${this.userPrefix}_${this.userID ?? '123'}`
    },
    historyButtonLabel() {
      return this.isChatHistoryOpen
        ? this.$t('aiChat.hideChatHistory')
        : this.$t('aiChat.showChatHistory')
    },
    groupedChatHistory() {
      return this.chatHistory.length > 0
        ? this.groupByMonth(this.chatHistory)
        : []
    },
  },
  methods: {
    ...mapActions('Chat', [
      'aiEnsure',
      'historyList',
      'historyRead',
      'fetchChatHistory',
    ]),
    handlePopupClick() {
      this.isChatVisible = !this.isChatVisible
    },
    onHistoryClick() {
      this.isChatHistoryOpen = !this.isChatHistoryOpen
    },
    async fetchHistory(offset: number) {
      const payload = {
        offset,
        config: { vSessionId: this.vSessionId },
      }
      const result = await this.historyList(payload)
      const conversations = await Promise.all(
        result.map(async (conv) => {
          let convMessages = []
          convMessages = await this.historyRead({
            conversation_id: conv.id,
            config: { vSessionId: this.vSessionId },
          })
          const conversation = {
            id: conv.id,
            title: conv.title,
            date: conv.createdAt,
            messages: convMessages,
          }
          return conversation
        })
      )
      return conversations
    },
    groupByMonth(entries: Conversation[]) {
      const groups: GroupedChatHistory[] = [{ month: 'Recent', entries: [] }]
      const currentDate = new Date()

      entries.forEach((entry) => {
        const date = new Date(entry.date)
        const daysDifference =
          (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
        const monthYear = date.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        })
        const existingGroup = groups.find((group) => group.month === monthYear)

        if (daysDifference <= 7) {
          groups[0].entries.push(entry)
        } else {
          if (existingGroup) {
            existingGroup.entries.push(entry)
          } else {
            groups.push({ month: monthYear, entries: [entry] })
          }
        }
      })

      groups.sort((a, b) => {
        // Check if either group has no entries and handle it
        if (a.entries.length === 0 && b.entries.length === 0) {
          return 0 // No change in order
        } else if (a.entries.length === 0) {
          return 1 // Move 'a' to a higher index (bottom)
        } else if (b.entries.length === 0) {
          return -1 // Move 'b' to a higher index (bottom)
        }
        const dateA = new Date(a.entries[0].date)
        const dateB = new Date(b.entries[0].date)
        return dateB.getTime() - dateA.getTime()
      })

      groups.forEach((group) => {
        group.entries.sort((a, b) => {
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          return dateB.getTime() - dateA.getTime()
        })
      })

      return groups
    },
    async loadMoreHistory() {
      this.showSpinner = true
      const chatHistory = await this.fetchHistory(this.chatHistory.length)
      if (chatHistory.length > 0) {
        this.fetchChatHistory([...this.chatHistory, ...chatHistory])
      }
      this.showSpinner = false
    },
    async initialChat() {
      this.showChatHistory = false
      const payload = {
        config: { vSessionId: this.vSessionId },
      }
      await this.aiEnsure(payload)
      await this.loadMoreHistory()
      this.showChatHistory = true
    },
  },
  async mounted() {
    await this.initialChat()
  },
})
